import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";
import './style.css';

const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.3); 
  border-radius: 10px;
`;



const DivButtons = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;





const InputArea = styled.div`
  width: 350px;
  height: auto; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px;
  margin: 10px;

  
`;

const Div = styled.div`

  display: flex;
  flex-direction: column;


  
`;



const Input = styled.input`
  width: 90%;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.3); 
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #515151;
  font-size: 16px;
  color: #fff;

`;



const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3); 
  background-color: #515151;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.0);
  }
`;










const Form = ({ urlApi, fetchColunas, editarNomeLista, modalEditarNomeLista }) => {
  const [inputValue, setInputValue] = useState('');


  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------//

  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };


  //Delete as tarefas
  const handleSave = async () => {



    const descricao_colunaValue = inputValue;
    const userConfirmed = await showPopUp();

    if (!userConfirmed) {
      return;
    }


    try {
      setTimeout(function () {
        modalEditarNomeLista();
      }, 1000);
      
      const response = await axios.put(
        `${urlApi}/gerenciamento/${editarNomeLista.id_coluna}`,
        { descricao_coluna: descricao_colunaValue },
        { headers: { Authorization: `Bearer ${token}` } }

        

      );
      console.log('Requisição bem-sucedida:', response); // Verifique o status e dados retornados
      fetchColunas();
      toast.success("Nome da lista alterado com sucesso.");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }

  };







  return (
    <>
      <FormContainer>
        <DivContainer  >

          <InputArea>
            <Label>Nome da lista:</Label>
            <Input
              value={editarNomeLista.descricao_coluna}
              readOnly
              disabled
            />
          </InputArea>
          <InputArea>
            <Label>Novo nome para lista:</Label>
            <Input
              placeholder="Digite aqui..."
              onChange={(e) => setInputValue(e.target.value)}

            />
          </InputArea>



        </DivContainer>

        <DivButtons>

          <Div>



            <Button onClick={handleSave} type="button">
              <FaCheck size={18} color={"green"} /> Salvar
            </Button>
          </Div>

        </DivButtons>
      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </ >

  );
};

export default Form;


