import React from "react";
import styled from "styled-components";


const Table = styled.table`
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 0.5px;
  margin: 1%;

`;

const Div = styled.div`
  display: flex;
  flex-direction: row


`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;


`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;


`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 

`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;


`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
`;

export const Td3 = styled.td`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  span{
    margin: 0px 5px;
  }

`;

const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


`;


const Button = styled.button`
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  width: 150px;
  background-color: #076633;
  color: white;
  height: auto;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
  transition: transform 0.3s;
  background: rgb(128, 128, 128); /* Fundo verde translúcido */
  border: 1px solid rgb(7, 102, 51); /* Borda verde translúcida */
  border-radius: 5px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;
  
  &:hover {
    transform: scale(1.1);
  }

`;

const Grid = ({  usuariosFiltrados }) => {





  

  return (

    <>

      <Table >
        <Thead>

          <Tr>
            <Th>Login</Th>
            <Th>Modulo</Th>
            <Th>Pagina</Th>
            <Th>Nº de Acessos</Th>

          </Tr>
        </Thead>
        <Tbody>
         {Array.isArray(usuariosFiltrados) && usuariosFiltrados.map((item, i) => (
            <Tr key={i}>
              <Td width="auto">{item.login}</Td>
              <Td width="auto">{item.desc_modulo}</Td>
              <Td width="auto">{item.desc_pagina}</Td>
              <Td width="auto">{item.acessos}</Td>
            </Tr>
          ))}
        </Tbody>


      </Table>


    </>
  );
};


export default Grid;
