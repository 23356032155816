import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { TbArrowsMoveHorizontal } from "react-icons/tb";
import { FaPlus } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";

//import Swal from "sweetalert2";

import './style.css'
import { useDebouncedPutAnexo } from "./useDebouncedPutAnexo.js";



const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
`;

const Div = styled.div`
  width: 95%;
  background-color:#515151;
  display: flex; 
  padding: 20px 10px 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;


const DivButtons = styled.div`
  max-width:100%;
  background-color: #515151;
  display: flex; 
  padding: 5px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;



const TextAreaContainer = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  flex-direction: column;
`;

const Hr = styled.div`
  display: flex;
  align-items: center; /* Alinha ao centro verticalmente */
  width: 95%; /* Largura total */
  height: 1px; /* Altura da linha */
  background-color: rgba(255, 255, 255, 0.3);
  justify-content: center;
  margin-left: 2%;
`;

const InputArea = styled.div`
  width: auto;
  height: auto; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;


  select {
    width: 190px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1px;
    padding: 0px 10px;
    background-color: #5d5d5d;
    font-size: 16px;
  }


  .save-button {
   
    right: 10px; /* Ajuste a posição à direita */
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%);
  }
`;





const InputTitle = styled.textarea`
  min-width: 600px; 
  min-height: 200px;
  font-size: 22px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  padding: 10px 20px;
  color: #fff;
  background-color: #515151;
  overflow: auto;
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
  line-height: 1.5; /* Ajustar o espaçamento entre as linhas */
  word-wrap: break-word; /* Permitir quebra de palavras longas */
`;


const InputDescricao = styled.textarea`
  min-width: 600px; 
  min-height: 200px;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  padding: 10px 10px 00px 20px;
  color: #fff;
  background-color: #515151;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
`;



const Input = styled.input`
  width: 170px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #515151;
  font-size: 16px;
  color: #fff;
`;



const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin: 0px 5px;
  padding: 0px 0px 0px 0px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #515151;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); /* Fundo translúcido */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;



const A = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 0px 0px 0px 10px; 
  margin: 0px 0px 0px 10px; 
`;



const Tr = styled.tr`
  display: flex; 
  justify-content: space-between;
  text-align: center;
  padding-bottom: 05px;

`;

export const Td = styled.td`
  font-size: 16px;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #fff;
  width: 400px;

`;

const Table = styled.table`
  width: 100%;
  background-color: #515151;
  padding: 5px 20px;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;


const TdButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #515151;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;



const Checkbox = styled.input`
  margin-right: 8px; 
`;


function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');


  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
const Form = ({ editarCartao, modalInfTarefa, urlApi, usuarios, checkbox, fetchCheckbox, modalNovaTarefa, modalMover }) => {

  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------//
  //Recebe os valores para editar, tipo onEdit
  const ref = useRef();
  useEffect(() => {
    if (editarCartao) {
      const user = ref.current;

      user.id_criador.value = editarCartao.id_criador;
      user.id_responsavel.value = editarCartao.id_responsavel;
      user.titulo_card.value = editarCartao.titulo_card;
      user.descricao_card.value = editarCartao.descricao_card;
      user.data_criacao.value = editarCartao.data_criacao;
      user.meta_conclusao.value = editarCartao.meta_conclusao;
      user.status.value = editarCartao.status;
      user.recorrente.value = editarCartao.recorrente;
      setImageUrl(editarCartao.id_card);
      setImageName(editarCartao.image);
    }

  }, [editarCartao]);


  //---------------------------------------------------------------------------//

  const [tituloCard, setTituloCard] = useState(editarCartao.titulo_card);
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
  const [originalTitulo, setOriginalTitulo] = useState(editarCartao.titulo_card); // Mantém o valor original

  const handleTituloChange = async (e) => {
    e.preventDefault();
    const newTitulo = e.target.value;
    setTituloCard(newTitulo);

    // Exibe o botão apenas se o título foi modificado
    setIsSaveButtonVisible(newTitulo !== originalTitulo);
  };

  const handleSave = async () => {
    try {
      setIsSaveButtonVisible(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-title/${editarCartao.id_card}`,
        { titulo_card: tituloCard },
        { headers: { Authorization: `Bearer ${token}` } }
      );


      setOriginalTitulo(tituloCard);  // Atualiza o valor original após salvar
      // Oculta o botão após o salvamento
      toast.success("Titulo salvo com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };
  //---------------------------------------------------------------------------//


  const [descricaoCard, setDescricaoCard] = useState(editarCartao.descricao_card);
  const [isSaveButtonVisibleDescricao, setIsSaveButtonVisibleDescricao] = useState(false);
  const [originalDescricao, setOriginalDescricao] = useState(editarCartao.descricao_card); // Mantém o valor original

  const handleDescricaoChange = async (e) => {
    e.preventDefault();
    const newDescricao = e.target.value;
    setDescricaoCard(newDescricao);

    // Exibe o botão apenas se a descrição foi modificada
    setIsSaveButtonVisibleDescricao(newDescricao !== originalDescricao); // Correção: aqui você precisa setar o estado correto
  };

  const handleSaveDescricao = async () => {
    try {
      setIsSaveButtonVisibleDescricao(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-desc/${editarCartao.id_card}`,
        { descricao_card: descricaoCard }, // Corrigido: enviar a descrição correta
        { headers: { Authorization: `Bearer ${token}` } }
      );


      setOriginalDescricao(descricaoCard);  // Atualiza o valor original após salvar
      toast.success("Descrição salva com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };


  //---------------------------------------------------------------------------//


  const { isFocused: isFocusedAnexo, setIsFocused: setIsFocusedAnexo, fieldValue: anexoCard, handleChange: handleAnexoChange, handleBlur: handleAnexoBlur
  } = useDebouncedPutAnexo(urlApi, editarCartao.id_card, 'image', token, editarCartao);

  //---------------------------------------------------------------------------//

  const [dataCartao, setDataCartao] = useState(editarCartao.meta_conclusao);
  const [isSaveButtonVisibleData, setIsSaveButtonVisibleData] = useState(false);
  const [originalData, setOriginalData] = useState(editarCartao.meta_conclusao); // Mantém o valor original

  const handleEntregaChange = async (e) => {
    e.preventDefault();
    const newTitulo = e.target.value;
    setDataCartao(newTitulo);

    // Exibe o botão apenas se o título foi modificado
    setIsSaveButtonVisibleData(newTitulo !== originalData);
  };
  //---------------------------------------------------------------------------//
  //Filtra as tarefas
  const checkFiltrados = checkbox.filter(v => v.id_card === editarCartao.id_card);


  const handleSaveData = async () => {
    // Verifica se o valor de dataCartao é menor do que algum meta_conclusao em checkFiltrados
    const dataCartaoDate = new Date(dataCartao); // Converte a data para comparação

    const isInvalidDate = checkFiltrados.some((check) => {
      const checkMetaConclusaoDate = new Date(check.meta_conclusao);
      return dataCartaoDate < checkMetaConclusaoDate;
    });

    if (isInvalidDate) {
      return toast.error("A data de conclusão não pode ser menor que uma das datas existentes nas tarefas. Por favor, escolha uma data válida.");

    }

    if (recorrente === "Sim" && !dataCartao) {
      return toast.warn("Por favor a data não pode ser vazia, pois é uma tarefa recorrente");
    }

    try {
      setIsSaveButtonVisibleData(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/cartao-meta/${editarCartao.id_card}`,
        { meta_conclusao: dataCartao },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOriginalData(dataCartao);  // Atualiza o valor original após salvar
      toast.success("Data salva com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };


  //---------------------------------------------------------------------------//



  const [imageName, setImageName] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);

  useEffect(() => {
    fetch(`${urlApi}/upload-gerenciamento/img/${imageUrl}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.ok) {
          setImageUrl2(response.url);
          console.log(response)
        } else {
          //console.error('Erro ao obter a imagem:', response.statusText);

        }
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
      });
  }, [imageUrl]);


  //---------------------------------------------------------------------------//

  const handleTarefa = () => {
    modalNovaTarefa();
  };

  const handleMover = () => {
    modalMover();
  };

  //---------------------------------------------------------------------------//
  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === editarCartao.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.login : '';
  //Renderiza o login do responsavel
  const usuarioResponsavel = usuarios.find(user => user.id_usuario === editarCartao.id_responsavel);
  const loginResponsavel = usuarioResponsavel ? usuarioResponsavel.login : '';







  //---------------------------------------------------------------------------//


  const handleCheckboxChange = async (id_check, isChecked) => {
    // Se estiver marcado, desmarcar e enviar null; senão, enviar a data atual
    const dataConclusao = isChecked ? null : getCurrentDateTime();

    const respondido = checkbox.find(user => user.id_check === id_check);
    if (respondido.resposta_check === null) {
      toast.error("Favor responda a tarefa, antes de concluir.");
      return;
    }

    try {
      const response = await axios.put(
        `${urlApi}/gerenciamento-checkbox/confirmar-tarefa/${id_check}`,
        { data_conclusao: dataConclusao },
        { headers: { Authorization: `Bearer ${token}` } }
      );


      fetchCheckbox(); // Atualiza a lista de checkboxes no frontend
    } catch (error) {
      console.error("Erro ao atualizar checkbox:", error);
      toast.error("Erro ao atualizar a tarefa.");
    }
  };

  //Informações da tarefa
  const handleInfTarefa = (check) => {
    modalInfTarefa(check);
  };





  const [message, setMensagem] = useState('');
  const [nameArquivo, setNameArquivo] = useState('');
  const uploadImageOnChange = async (e) => {
    const selectedImage = e.target.files[0];

    const formData = new FormData();
    formData.append('image', selectedImage);
    setNameArquivo(selectedImage.name);
    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = await axios.post(`${urlApi}/upload-gerenciamento/upload-image`, formData, headers);
      setMensagem(response.data.message);
    } catch (err) {
      if (err.response) {
        setMensagem(err.response.data.message);
      } else {
        setMensagem("Erro: verifique o tipo de arquivo que está sendo anexado")
      }
    }
  };



  //---------------------------------------------------------------------------//

  const [status, setStatus] = useState(editarCartao.status);
  const [isSaveButtonVisibleStatus, setIsSaveButtonVisibleStatus] = useState(false);
  const [originalStatus, setOriginalStatus] = useState(editarCartao.status); // Mantém o valor original

  const handleStatusChange = async (e) => {
    e.preventDefault();
    const novoStatus = e.target.value;
    setStatus(novoStatus);

    // Exibe o botão apenas se o status foi modificado
    setIsSaveButtonVisibleStatus(novoStatus !== originalStatus);
  };

  const handleSaveStatus = async () => {
    try {
      setIsSaveButtonVisibleStatus(false);

      await axios.put(
        `${urlApi}/gerenciamento-card/status-cartao/${editarCartao.id_card}`,
        { status: status },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOriginalStatus(status);  // Atualiza o valor original após salvar
      toast.success("Status salvo com sucesso!");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Erro ao salvar o status";
      toast.error(errorMessage);
    }
  };

  //---------------------------------------------------------------------------//

  const [recorrente, setRecorrente] = useState(editarCartao.recorrente);
  const [isSaveButtonVisibleRecorrente, setIsSaveButtonVisibleRecorrente] = useState(false);
  const [originalRecorrente, setOriginalRecorrente] = useState(editarCartao.recorrente); // Mantém o valor original

  const handleRecorrenteChange = async (e) => {
    e.preventDefault();
    const novoRecorrente = e.target.value;
    setRecorrente(novoRecorrente);

    // Exibe o botão apenas se o status foi modificado
    setIsSaveButtonVisibleRecorrente(novoRecorrente !== originalRecorrente);
  };

  const handleSaveRecorrente = async () => {

    // Verificação específica para recorrente = 'Sim'
    if (recorrente === "Sim" && !dataCartao) {
      return toast.warn("Por favor, selecione uma data para 'Entrega' quando recorrente for 'Sim'.");
    }

    try {
      setIsSaveButtonVisibleRecorrente(false);
      console.log('recorrente', recorrente)
      await axios.put(
        `${urlApi}/gerenciamento-card/recorrente-cartao/${editarCartao.id_card}`,
        { recorrente: recorrente },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setOriginalRecorrente(recorrente);  // Atualiza o valor original após salvar
      toast.success("Recorrente salvo com sucesso!");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Erro ao salvar o recorrente";
      toast.error(errorMessage);
    }
  };

  //---------------------------------------------------------------------------//

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" "); // Pega apenas a parte da data, ignorando a hora
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };
  return (
    <div >
      <FormContainer>
        <DivContainer ref={ref} >

          <TextAreaContainer >
            <InputTitle
              id="titulo_card"
              type="text"
              value={tituloCard}  // Bind do valor ao estado
              onChange={handleTituloChange}
            />

            {((id === editarCartao.id_criador || id === editarCartao.id_responsavel) && isSaveButtonVisible) && (
              <Button type="button" onClick={handleSave}>Salvar alteração</Button>
            )}
          </TextAreaContainer>

          <Div>

            <InputArea>
              <Label>Criador:</Label>
              <Input
                name="id_criador"
                value={loginCriador}
                readOnly
                disabled
              />
            </InputArea>

            <InputArea>
              <Label>Responsavel:</Label>
              <Input
                name="id_responsavel"
                value={loginResponsavel}
                readOnly
                disabled
              />
            </InputArea>

            <InputArea>
              <Label>Criado:</Label>
              <Input
                name="data_criacao"
                readOnly
                disabled
                value={formatDate(editarCartao.data_criacao)}
              />
            </InputArea>

          </Div>
          <Div>
            <InputArea>
              <Label>Situação:</Label>
              <select name="status" onChange={handleStatusChange} value={status}>
                <option value="" disabled>Escolha....</option>
                <option value="A Fazer">A Fazer</option>
                <option value="Programado">Programado</option>
                <option value="Em Andamento">Em Andamento</option>
                <option value="Pausado">Pausado</option>
                <option value="Concluído">Concluído</option>
                <option value="Cancelado">Cancelado</option>
              </select>
              <div>
                {((id === editarCartao.id_criador || id === editarCartao.id_responsavel) && isSaveButtonVisibleStatus) && (
                  <Button type="button" onClick={handleSaveStatus}>Salvar alteração</Button>
                )}
              </div>
            </InputArea>

            <InputArea >
              <Label>Entrega:</Label>

              <Input
                name="meta_conclusao"
                type="date"
                onChange={handleEntregaChange}
                value={dataCartao}
              />
              <div>
                {(id === editarCartao.id_criador && isSaveButtonVisibleData) && (
                  <Button type="button" onClick={handleSaveData}>Salvar</Button>
                )}
              </div>
            </InputArea>

            <InputArea>
              <Label>Recorrente:</Label>

              <select name="recorrente" onChange={handleRecorrenteChange} value={recorrente}>
                <option value="" disabled>Escolha....</option>
                <option value="Sim">Sim, vencimento mensal</option>
                <option value="Não">Não</option>
              </select>
              <div>
                {((id === editarCartao.id_criador || id === editarCartao.id_responsavel) && isSaveButtonVisibleRecorrente) && (
                  <Button type="button" onClick={handleSaveRecorrente} >Salvar alteração</Button>
                )}
              </div>
            </InputArea>

          </Div>





          <Div>
            <TextAreaContainer>
              <Label>Descrição:</Label>
              <InputDescricao
                value={descricaoCard}
                onChange={handleDescricaoChange}
                id="descricao_card"
              />
              {((id === editarCartao.id_criador || id === editarCartao.id_responsavel) && isSaveButtonVisibleDescricao) && (
                <Button type="button" onClick={handleSaveDescricao}>Salvar alteração</Button>
              )}

            </TextAreaContainer>
          </Div>



          <>
            <Div>
              <Table>
                <>
                  {checkFiltrados.length > 0 && (

                    <Tbody>
                      {checkFiltrados.map((check) => {
                        // Função find para buscar o login do responsável
                        const usuarioResponsavelTarefa = usuarios.find(user => user.id_usuario === check.id_responsavel_tarefa);
                        const loginResponsavelTarefa = usuarioResponsavelTarefa ? usuarioResponsavelTarefa.login : 'Responsável não encontrado';

                        return (
                          <Tr key={check.id_check}>
                            <Td width="75%" style={{ textAlign: 'left' }}>
                              {(id === check.id_responsavel_tarefa) && (
                                <Checkbox
                                  type="checkbox"
                                  checked={check.data_conclusao !== null}
                                  onChange={() => handleCheckboxChange(check.id_check, check.data_conclusao !== null)}
                                />
                              )}

                              {check.descricao_check}

                              <div style={{ marginTop: '5px', color: '#BFBFBF', marginLeft: '50px' }}>
                                Responsável: {loginResponsavelTarefa} {/* Exibe o login do responsável */}
                              </div>


                            </Td>

                            <TdButton width="5%" onClick={() => handleInfTarefa(check)} style={{ textAlign: 'right' }} type='button'>
                              <FaInfo size={18} color="Gold" />
                            </TdButton>

                          </Tr>
                        );
                      })}
                    </Tbody>


                  )}
                </>

              </Table>
            </Div>
          </>

          <Hr />

          <Div>
            {imageUrl2 ? (
              <A href={imageUrl2} target="_blank" rel="noopener noreferrer">
                <p>Arquivo: {imageName}</p>
              </A>
            ) : (
              <A>Nenhum arquivo anexado.</A>
            )}

          </Div>

          <form>
            {(id === editarCartao.id_criador || id === editarCartao.id_responsavel) && (  // Corrige a condicional para verificar se o usuário é o criador
              <>
                <Label>Anexo:</Label>
                {message ? <p>{message}</p> : ""}
                <input
                  type="file"
                  name="image"
                  onChange={(event) => {
                    uploadImageOnChange(event);  // Chama a função para salvar o arquivo no servidor
                    handleAnexoChange(event);    // Chama a função para salvar o nome da imagem no banco
                  }}
                  onFocus={() => setIsFocusedAnexo(true)}
                  onBlur={handleAnexoBlur}       // Chama a função de blur para o debounce
                  className={isFocusedAnexo ? 'input-focused' : ''}
                  disabled={id !== editarCartao.id_criador}  // Desabilita se não for o criador
                />

              </>
            )}
          </form>

        </DivContainer>

        <FormContainer>
          <DivButtons>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              {(id === editarCartao.id_criador || id === editarCartao.id_responsavel) && (
                <Button onClick={handleTarefa} type="button">
                  <FaPlus size={18} color={"gold"} /> Criar/Alterar Tarefas
                </Button>
              )}

              {(id === editarCartao.id_criador) && (
                <Button onClick={handleMover} type="button">
                  <TbArrowsMoveHorizontal size={18} color={"blue"} /> Mover Cartão
                </Button>
              )}
            </div>

          </DivButtons>
        </FormContainer>
      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div >

  );
};

export default Form;


