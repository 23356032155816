import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ tAcesso, onModuleSelect }) => {
  const seriesData = Array.isArray(tAcesso) ? tAcesso.map(item => item.acessos) : [];
  const categoriesData = Array.isArray(tAcesso) ? tAcesso.map(item => item.desc_modulo) : [];
  const idsData = Array.isArray(tAcesso) ? tAcesso.map(item => item.id_modulo) : []; // Obtenha os IDs dos módulos

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      width: categoriesData.length * 100,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedId = idsData[config.dataPointIndex]; // Obtenha o ID do módulo correspondente ao índice do ponto de dados selecionado
          onModuleSelect(selectedId); // Chame a função de callback com o ID do módulo
        }
      }
    },
    colors: ['#04a44c'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, { dataPointIndex, w }) {
        const acessos = seriesData[dataPointIndex];
        return `${acessos}`;
      },
      style: {
        colors: ['#333'],
        fontSize: '14px',
        fontWeight: 'bold'
      },
      offsetX: 15,
      textAnchor: 'start',
    },
    xaxis: {
      categories: categoriesData, // Use as descrições dos módulos como categorias
    },
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const userName = categoriesData[dataPointIndex];
        const acessos = series[seriesIndex][dataPointIndex];
        return (
          `<div style="background: #333; color: #fff; padding: 10px; border-radius: 5px; text-align: center;">
            <strong>${userName}</strong><br />${acessos} acessos
          </div>`
        );
      },
    },
  };

  const series = [{
    data: seriesData, // Use os dados de acesso como a série
  }];

  return (
    <div style={{ overflowX: 'auto' }}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
};

export default BarChart;
