import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";



const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: 500px;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.3); 
  padding: 0px 0px 20px 0px 
`;

const Div = styled.div`
  width:95%;
  background-color: #515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
  margin-top: 10px;
`;

const Div2 = styled.div`
  width:95%;
  background-color: #515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  justify-content: center;
  gap: 20px 0px;
  flex-direction: row;
  margin-top: 10px;
`;


const InputArea = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;



`;






const InputTitle = styled.textarea`
  color: #fff;
  width: 100%; 
  min-height: 100px; 
  border: 1px solid rgba(255, 255, 255, 0.3); 
  border-radius: 1px;
  padding: 10px;
  background-color: transparent;
  font-size: 20px;
  resize: none; 
  overflow: hidden; 
  box-sizing: border-box; 
  white-space: pre-wrap; 
`;





export const Td = styled.td`
  font-size: 14px;
  word-break: break-word;
  overflow-wrap: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  white-space: normal;
  max-width: 400px; /* Limita a largura máxima */
  overflow: hidden;
  text-overflow: ellipsis; /* Para adicionar "..." se necessário */
`;

const Table = styled.table`
  width: 800px;
  background-color: #515151;
  padding: 5px 20px;
  color: #fff;
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;

export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
`;

const Checkbox = styled.input`
  margin-right: 8px; /* Espaço à direita do checkbox */
`;


const Input = styled.input`
  width: 100%;
  padding: 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.3); 
  border-radius: 1px;
  height: 30px;

  background-color: #5d5d5d;
  color: #fff;
`;

const Form = ({ urlApi, usuarios, verQuadro, visualizador, fetchVisualizador }) => {
  const ref = useRef();


  useEffect(() => {
    if (verQuadro) {
      const user = ref.current;

      user.titulo_quadro.value = verQuadro.titulo_quadro;
    }

  }, [verQuadro]);


  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));







  const [selectedUsers, setSelectedUsers] = useState({});

  useEffect(() => {
    if (!visualizador) return;
    // Inicializa o estado selectedUsers com base nos dados do visualizador
    const initialSelectedUsers = {};
    visualizador.forEach((v) => {
      if (v.id_quadro === verQuadro.id_quadro) {
        initialSelectedUsers[v.id_usuario] = true;
      }
    });
    setSelectedUsers(initialSelectedUsers);
  }, [visualizador, verQuadro.id_quadro]);






  const handleCheckbox = (id_usuario) => {
    const isSelected = selectedUsers[id_usuario];

    setSelectedUsers((prev) => ({
      ...prev,
      [id_usuario]: !isSelected,
    }));


    if (!isSelected) {
      // Adicionar ao banco de dados
      const data = {
        id_quadro: verQuadro.id_quadro,
        id_usuario: id_usuario,
      };


      const usuarioEmail2 = usuarios.find(user => user.id_usuario === data.id_usuario);
      const emailRecebe2 = usuarioEmail2 ? usuarioEmail2.email : 'Usuário não encontrado';
      const nomeRecebe = usuarioEmail2 ? usuarioEmail2.nome : 'Usuário não encontrado';

      axios
        .post(`${urlApi}/visualizador-gerenciamento`, data, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          fetchVisualizador(); // Atualiza os visualizadores após a adição
          enviarEmail(emailRecebe2, nomeRecebe);
          toast.success(`Quadro compartilhado com ${nomeRecebe}!`);
        })
        .catch((error) => {
          console.error('Erro ao adicionar visualizador:', error);
        });

    } else {
      // Remover do banco de dados
      const visualizadorToDelete = visualizador.find(
        (v) => v.id_usuario === id_usuario && v.id_quadro === verQuadro.id_quadro
      );

      if (visualizadorToDelete) {
        axios
          .delete(`${urlApi}/visualizador-gerenciamento/${visualizadorToDelete.id_visualizador}`, { headers: { Authorization: `Bearer ${token}` } })
          .then(() => {
            fetchVisualizador(); // Atualiza os visualizadores após a exclusão
            toast.success(`Compartilhamento removido com sucesso!`);
          })
          .catch((error) => {
            console.error('Erro ao remover visualizador:', error);
          });
      }
    }
  };




  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === verQuadro.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.nome : '';

  const enviarEmail = (emailRecebe2, nomeRecebe) => {
    axios.get(`${urlApi}/email-gerenciamento/visualizador`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { userTo: emailRecebe2, criador: loginCriador, titulo: verQuadro.titulo_quadro, nomeUsuario: nomeRecebe }
    })
      .then(response => {
        console.log("E-mail enviado com sucesso:", response.data.message);
      })
      .catch(error => {
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Headers:", JSON.stringify(error.response.headers, null, 2));
          console.error("Data:", error.response.data);
        } else if (error.request) {
          console.error("Request não respondida:", error.request);
        } else {
          console.error("Erro na configuração da requisição:", error.message);
        }
        console.error("Configuração completa:", error.config);
      });
  };

  const usuariosVisualizadores = usuarios
    .filter(user => user.id_usuario !== id);



  //states do pesquisar
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filtroUsuario, setFiltroUsuario] = useState(usuariosVisualizadores);

  const handleSearch = async (term) => {
    setIsLoading(true);

    try {
      const filtered = usuariosVisualizadores.filter((user) => {
        const columnsToSearch = [
          'nome',
        ];

        // Verifica se algum dos valores nas colunas contém o termo de pesquisa
        return columnsToSearch.some((column) => {
          const columnValue = user[column] ? user[column].toLowerCase() : '';
          return columnValue.includes(term.toLowerCase());
        });
      });
      setFiltroUsuario(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    handleSearch(searchTerm);

  }, [searchTerm, usuariosVisualizadores]);


  return (
    <div >
      <FormContainer  >
        <DivContainer ref={ref} >


          <Div>
            <InputArea>
              <InputTitle
                name="titulo_quadro"
                disabled
              />
            </InputArea>
          </Div>
          <Div2>
            <Input
              type="text"
              placeholder="Pesquisar nome do usuário..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Div2>

          <Div>
            <Table>
              <Tbody>
                {filtroUsuario.map((usuario) => (
                  <div key={usuario.id_usuario}>
                    <Checkbox
                      type="checkbox"
                      onChange={() => handleCheckbox(usuario.id_usuario)}
                      checked={!!selectedUsers[usuario.id_usuario]}
                    />
                    {usuario.login} - {usuario.nome}
                  </div>
                ))}
              </Tbody>
            </Table>
          </Div>


        </DivContainer>
      </FormContainer>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


