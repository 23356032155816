import { useEffect, useState } from "react";

const VideoComponent = ({urlApi}) => {

  const token = localStorage.getItem('token');

  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  useEffect(() => {
    const fetchVideo = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${urlApi}/reproduzir/videos/portal-colaborador.mp4`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
      
          if (!response.ok) {
            const errorText = await response.text(); // Captura o erro exato retornado pelo servidor
            throw new Error(`Erro ao carregar o vídeo: ${errorText}`);
          }
      
          const blob = await response.blob();
          const videoObjectUrl = URL.createObjectURL(blob);
          setVideoUrl(videoObjectUrl);
        } catch (error) {
          console.error("Erro ao carregar o vídeo:", error);
          setError(`Erro ao carregar o vídeo: ${error.message}`);
        } finally {
          setLoading(false);
        }
      };

    fetchVideo();

    return () => {
      if (videoUrl) URL.revokeObjectURL(videoUrl); // Limpeza do Blob URL ao desmontar
    };
  }, [urlApi, token]);

  if (loading) return <p>Carregando o vídeo...</p>;
  if (error) return <p>{error}</p>;

  return (
    <video controls src={videoUrl} style={{ width: "700px", height: "auto" }}>
      Seu navegador não suporta a reprodução de vídeos.
    </video>
  );
};

export default VideoComponent;
