import React, { useState } from 'react';
import './Sidebar.css';
import styled from "styled-components";
import VideoPortal from './video-portal-colaborador.js';
import VideoPainel from './video-painel-monitoramento.js';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  overflow: hidden; /* Evita rolagem extra */
  gap: 25px;
`;



const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  gap: 30px; 
  z-index: 1; 
`;
const ContentWrapper1 = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  gap: 30px; 
  z-index: 1; 
`;


const ButtonDesat = styled.button`
  width: 250px;
  height: 90px;
  padding: 10px 20px;
  font-size: 24px;
  color: white; /* Cor do texto */
  background: gray; /* Fundo verde translúcido */
  border: 1px solid gray; /* Borda verde translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  backdrop-filter: blur(10px); /* Efeito de desfoque */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s, border 0.3s;

 
`;




const Grid = ({ urlApi }) => {


  const [selectedVideo, setSelectedVideo] = useState(null);

  // Funções para definir qual vídeo exibir
  const showPortalVideo = () => {
    setSelectedVideo('portal');
  };

  const showPainelVideo = () => {
    setSelectedVideo('painel');
  };

  return (
    <>
      <Container>
        <ContentWrapper1>
          <ButtonDesat onClick={showPortalVideo}>Portal do Colaborador</ButtonDesat>
          <ButtonDesat onClick={showPainelVideo}>Painel de Monitoramento</ButtonDesat>
        </ContentWrapper1>
        {selectedVideo ? (
          <ContentWrapper>
            {/* Exibir o vídeo correspondente ao estado selecionado */}
            {selectedVideo === 'portal' && <VideoPortal urlApi={urlApi} />}
            {selectedVideo === 'painel' && <VideoPainel urlApi={urlApi} />}
          </ContentWrapper>
        ) : (
          null
        )}


      </Container>
    </>
  );
};

export default Grid;
