import { createGlobalStyle } from "styled-components";



const Global = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, Helvetica, Arial, sans-serif;
  }
  
  
  body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    margin: 0; 
    background-color: #f5f5f5;

  }
  
   /* Estilizando a scrollbar para toda a aplicação */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 3px solid #f0f0f0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;



export default Global;