
import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import styled from "styled-components";
import './style.css';
import { toast } from "react-toastify";



const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: row;

`;

const DivContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
`;

const Div = styled.div`
  width: 100%;
  background-color:#515151;
  display: flex; 
  padding: 20px 10px 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;

  textearea {
    display: flex; 
    border: 1px solid rgba(255, 255, 255, 0.3);
    flex-direction: row;
    min-width: 600px; 
    min-height: 40px;
  }
  p{
    color: #fff;
    padding: 0px 0px 0px 10px;
  }
 
`;



const TextAreaContainer = styled.div`
  width: 100%;
  height: auto; 
  display: flex;
  flex-direction: column;
`;


const InputTitle = styled.textarea`
  min-width: 600px; 
  min-height: 80px;
  font-size: 22px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  padding: 10px 20px;
  color: #fff;
  background-color: #515151;
  overflow: auto;
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
  line-height: 1.5; /* Ajustar o espaçamento entre as linhas */
  word-wrap: break-word; /* Permitir quebra de palavras longas */
`;


const InputDescricao = styled.textarea`
  min-width: 600px; 
  min-height: 200px;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  padding: 10px 10px 00px 20px;
  color: #fff;
  background-color: #515151;
  resize: none; /* Desabilitar o redimensionamento manual */
  box-sizing: border-box; /* Incluir o padding e a borda na largura total */
  overflow: auto; /* Permitir a rolagem automática */
  white-space: pre-wrap; /* Manter quebras de linha e espaços */
`;





const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin: 0px 5px;
  padding: 0px 0px 0px 10px; 


`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #515151;
  height: 42px;
  font-weight: bold;
  margin: 5px;

  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); /* Fundo translúcido */

  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;


export const Td = styled.td`
  font-size: 16px;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #fff;
  width: 400px;

`;


export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 05px; 
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;
`;



const Form = ({ editarQuadro, modalVerQuadro, setVerQuadro, visualizador, usuarios, urlApi, fetchQuadro }) => {

  //Token da api
  const token = localStorage.getItem('token');
  const id = parseInt(localStorage.getItem('id'));

  //---------------------------------------------------------------------------//
  //Recebe os valores para editar, tipo onEdit
  const ref = useRef();
  useEffect(() => {
    if (editarQuadro) {
      const user = ref.current;

      user.descricao_quadro.value = editarQuadro.descricao_quadro;
      user.titulo_quadro.value = editarQuadro.titulo_quadro;
    }

  }, [editarQuadro]);



  //---------------------------------------------------------------------------//


  const handleVerQuadro = () => {
    setVerQuadro(editarQuadro); // Atualiza o estado após o fetch
    modalVerQuadro(); // Abre o modal
  };



  //---------------------------------------------------------------------------//

  // Filtra os itens onde `id_quadro` corresponde ao quadro atual
  const filtrandoCompartilhados = visualizador.filter(v => v.id_quadro === editarQuadro.id_quadro);


  // Filtra os usuários cujos `id_usuario` estão em `filtrandoCompartilhados`
  const filtrandoUsuarios = usuarios.filter(user =>
    filtrandoCompartilhados.some(compartilhado => compartilhado.id_usuario === user.id_usuario)
  );


  //---------------------------------------------------------------------------//

  const [tituloQuadro, setTituloQuadro] = useState(editarQuadro.titulo_quadro);
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
  const [originalTitulo, setOriginalTitulo] = useState(editarQuadro.titulo_quadro); // Mantém o valor original

  const handleTituloChange = async (e) => {
    e.preventDefault();
    const newTitulo = e.target.value;
    setTituloQuadro(newTitulo);

    // Exibe o botão apenas se o título foi modificado
    setIsSaveButtonVisible(newTitulo !== originalTitulo);
  };

  const handleSave = async () => {
    try {
      setIsSaveButtonVisible(false);

      await axios.put(
        `${urlApi}/quadro/quadro-title/${editarQuadro.id_quadro}`,
        { titulo_quadro: tituloQuadro },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const quadroAtualizado = await fetchQuadro();
      setVerQuadro(quadroAtualizado);
      setOriginalTitulo(tituloQuadro);  // Atualiza o valor original após salvar
      // Oculta o botão após o salvamento
      toast.success("Titulo salvo com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };

  //---------------------------------------------------------------------------//

  const [descricaoQuadro, setDescricaoQuadro] = useState(editarQuadro.descricao_quadro);
  const [isSaveButtonVisibleDescricao, setIsSaveButtonVisibleDescricao] = useState(false);
  const [originalDescricao, setOriginalDescricao] = useState(editarQuadro.descricao_quadro); // Mantém o valor original

  const handleDescricaoChange = async (e) => {
    e.preventDefault();
    const newDescricao = e.target.value;
    setDescricaoQuadro(newDescricao);

    // Exibe o botão apenas se a descrição foi modificada
    setIsSaveButtonVisibleDescricao(newDescricao !== originalDescricao); // Correção: aqui você precisa setar o estado correto
  };

  const handleSaveDescricao = async () => {
    try {
      setIsSaveButtonVisibleDescricao(false);

      await axios.put(
        `${urlApi}/quadro/quadro-descricao/${editarQuadro.id_quadro}`,
        { descricao_quadro: descricaoQuadro }, // Corrigido: enviar a descrição correta
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const quadroAtualizado = await fetchQuadro();
      setVerQuadro(quadroAtualizado);
      setOriginalDescricao(descricaoQuadro);  // Atualiza o valor original após salvar
      toast.success("Descrição salva com sucesso!");
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div >
      <FormContainer>
        <DivContainer ref={ref} >

          <TextAreaContainer>
            <InputTitle
              id="titulo_quadro"
              type="text"
              value={tituloQuadro}
              onChange={handleTituloChange}
            />

            {(id === editarQuadro.id_criador && isSaveButtonVisible) && (
              <Button type="button" onClick={handleSave}>Salvar alteração</Button>
            )}
          </TextAreaContainer>



          <Div>
            <TextAreaContainer>
              <Label>Descrição:</Label>
              <InputDescricao
                id="descricao_quadro"
                value={descricaoQuadro}
                onChange={handleDescricaoChange}
              />

              {(id === editarQuadro.id_criador && isSaveButtonVisibleDescricao) && (
                <Button type="button" onClick={handleSaveDescricao}>Salvar alteração</Button>
              )}
            </TextAreaContainer>
          </Div>

          <Div>
            <TextAreaContainer>
              <Label>Quadro compartilhado com:</Label>
              <div>
                <textearea>
                  {filtrandoUsuarios.map((usuario) => (
                    <p key={usuario.id_usuario}>
                      {usuario.nome},
                    </p>
                  ))}
                </textearea>

              </div>

            </TextAreaContainer>
          </Div>
          <Button title="Salvar" type="button" onClick={handleVerQuadro}>
            Ver quadro
          </Button>

        </DivContainer>


      </FormContainer>


      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div >

  );
};

export default Form;


