import React, { useState, useEffect } from "react";
import axios from 'axios';

import Frame from './components/Frame.js';

import GlobalStyle from "../../../styles/global2.js";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { ImExit } from "react-icons/im";
import { toast, ToastContainer } from "react-toastify";
import { SlSizeFullscreen } from "react-icons/sl";
import { RiArrowGoBackFill } from "react-icons/ri";


const Geral = styled.div`
  div {
    cursor: pointer;
    transition: transform 0.1s;
    background-color: ${(props) => (props.currentUrl ? 'gray' : '#076633')};
    border-radius: ${(props) => (props.currentUrl ? '3px' : '3px')};
  }
`;

const Div = styled.div`
  width: 100%;
  margin-left: 65px;
`;

const DivLayout = styled.aside`
  width: 60px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) => (props.isOpen ? "250px" : "60px")};
  //background-color: #11101d;
  background-color: #076633;
  color: white;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
`;

const Divbutton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Divbuttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0px 0px 20px 5px;
`;


const ButtonSide2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #076633;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  gap: 10px;
  transition: transform 0.1s;

  &:hover {
    color: #11101d;
  }

`;

const ButtonSide = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #076633;
  font-size: 18px;
  font-weight: bold;  // Adiciona negrito
  color: #fff;
  padding: 10px;
  gap: 10px;
  transition: transform 0.1s;

  &:hover {
    color: #11101d;
  }
`;








function App() {

  //----------------------------//
  //Ip's da api e do front
  //const urlFront = "http://192.168.100.103:3050/indicadores";
  //const urlApi = "http://192.168.100.103:8850/api";
  //const urlLogin = "http://192.168.100.103:3050/";
  const urlFront = "/indicadores/";
  const urlApi = "/api";
  const urlLogin = "/";

  //----------------------------//

  //Token da api
  const token = localStorage.getItem('token');



  //----------------------------//

  //Passa os links para o Frame.js redenrizar
  const [currentUrl, setCurrentUrl] = useState(null);


  //----------------------------//


  //Links
  const handle162 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:162`;
    setCurrentUrl(url);
  };

  const handle175 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:175`;
    setCurrentUrl(url);
  };

  const handle183 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:183`;
    setCurrentUrl(url);
  };

  const handle188 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:188`;
    setCurrentUrl(url);
  };

  const handle197 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:197`;
    setCurrentUrl(url);
  };

  const handle198 = () => {
    const url = `https://www.cooperval.coop.br/apex/f?p=CSBI:3:12339197809022::NO::P3_PLANILHA_DADOS:198`;
    setCurrentUrl(url);
  };

  const handleExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');

    const url = `${urlLogin}login`;
    window.location.href = url;
  };



  //----------------------------//

  //Verifica o token do usuário
  const getToken = async () => {
    try {
      const res = await axios.get(`${urlApi}/login/token`, { headers: { Authorization: `Bearer ${token}` } });

    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
        window.location.href = `${urlFront}/login`;
      } else {
        console.error("Erro ao obter token:", error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getToken();
    }, 60000);


    return () => clearInterval(intervalId);
  }, [])



  //----------------------------//


  //Efeitos do sidebar
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {

    setIsOpen(!isOpen);
  };




  //----------------------------//


  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };


  const toggleInicio = () => {
    const url = `${urlLogin}`;
    window.location.href = url;
  };




  return (
    <>
      <Geral>


        <Div>
          <Frame currentUrl={currentUrl} />
        </Div>

        <DivLayout isOpen={isOpen} >
          <Divbutton>

            <ButtonSide2 onClick={toggleInicio} title="Tela inicial">
              {isOpen ? <><RiArrowGoBackFill /> Inicio</> : <RiArrowGoBackFill />}
            </ButtonSide2>

          </Divbutton>

          <ul>
            <ButtonSide onClick={handle162} >
              162
            </ButtonSide>
            <ButtonSide onClick={handle175} >
              175
            </ButtonSide>
            <ButtonSide onClick={handle183} >
              183
            </ButtonSide>
            <ButtonSide onClick={handle188} >
              188
            </ButtonSide>
            <ButtonSide onClick={handle197} >
              197
            </ButtonSide>
            <ButtonSide onClick={handle198} >
              198
            </ButtonSide>

          </ul>

          <Divbuttons>

            <ButtonSide2 onClick={toggleFullscreen} title="Tela cheia">

              {isOpen ? <><SlSizeFullscreen /> Tela cheia</> : <SlSizeFullscreen />}

            </ButtonSide2>


            {isOpen ? (
              <>
                <ButtonSide2 onClick={handleExit} title="Sair do portal">
                  {isOpen ? <><ImExit /> Sair</> : <ImExit />}

                </ButtonSide2>
              </>


            ) : null}


          </Divbuttons>

        </DivLayout>

        <GlobalStyle />
      </Geral>
      <ToastContainer autoClose={2500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
    </>
  );
}

export default App;
