import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";
import Swal from "sweetalert2";
import './style.css';

const FormContainer = styled.form`
  width: auto;
  background-color: #515151;
  display: flex; 
  align-items: flex-start;
  flex-direction: column;

`;

const Div = styled.div`
  max-width:100%;
  background-color:#515151;
  display: flex; 
  padding: 10px 10px;
  align-items: flex-end;
  gap: 20px 0px;
  flex-direction: row;
 
`;


const TextAreaContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  
  textarea{
    width: 500px;
    height: 100px;
    background-color: #5d5d5d;
    border: 1px solid #ccc;
    color: #fff
  }


`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;

  select {
    width: 220px;
    height: 41.5px;
    padding: 0 0px;
    border: 1px solid #ccc;
    border-radius: 1px;
    background-color: #5d5d5d;
    color: #fff;
  }

`;
const InputArea2 = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;

  

`;
const Select2 = styled.select`
  width: 120px;
  height: 41.5px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 1px;
  background-color: #5d5d5d;
  color: #fff;
`;

const Input = styled.input`
  width: 120px;
  height: 40px;
  border: 1px solid #ccc;
  background-color: #5d5d5d;
  border-radius: 1px;
  padding: 0px 10px;
  color: #fff;
`;
const InputTitle = styled.input`
  width: 480px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 1px;
  padding: 0px 10px;
  background-color: #5d5d5d;
  color: #fff;
`;

const Label = styled.label`
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  margin: 0px 5px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
  padding-left: 1px;
  flex-direction: column;

  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 150px;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #5d5d5d;
  height: 42px;
  font-weight: bold;
  margin: 5px;
  transition: transform 0.1s;
  gap: 10px;
  background: rgba(255, 255, 255, 0.1); 
  border: 1px solid rgba(255, 255, 255, 0.9); /* Borda translúcida */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.1s, background 0.3s;
  color: #fff;
  &:hover {
    transform: scale(1.1);
  }
`;




const Form = ({ urlApi, modalNovoCard, novoCard, fetchCard, usuarios, visualizador, quadro }) => {
  const ref = useRef();


  //Token da api
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('id');


  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container', // Estiliza o container principal
          title: 'custom-swal-title', // Estiliza o título
          confirmButton: 'custom-swal-confirm-button', // Estiliza o botão de confirmação
          cancelButton: 'custom-swal-cancel-button', // Estiliza o botão de cancelamento
          popup: 'custom-swal-popup', // Estiliza o popup
          icon: 'custom-swal-icon' // Estiliza o ícone
        },
      }).then((result) => {
        resolve(result.value);
      });
    });
  };



  //fornece o id para selecionar o email
  const [usuarioEmail, setUsuarioEmail] = useState();
  const [tituloCartao, setTituloCartao] = useState();
  const [recorrente, setRecorrente] = useState("");

  const handleCheckboxChange = (e) => {
    setRecorrente(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = ref.current;


    const id_criadorValue = id;
    const id_responsavelValue = user.id_responsavel.value;
    const titulo_cardValue = user.titulo_card.value;
    const descricao_cardValue = user.descricao_card.value;
    const meta_conclusaoValue = user.meta_conclusao.value;
    const recorrenteValue = recorrente;

    // Verificação específica para recorrente = 'Sim'
    if (recorrenteValue === "Sim" && !meta_conclusaoValue) {
      return toast.warn("Por favor, selecione uma data para 'Data de Entrega' quando recorrente for 'Sim'.");
    }

    if (!titulo_cardValue || !descricao_cardValue || !id_responsavelValue) {
      return toast.warn("Preencha todos os campos!");


    } else {
      const userConfirmed = await showPopUp(); // Aguarde a resolução da Promise



      if (!userConfirmed) {
        return;
      }




      try {
        const requestData1 = {
          id_coluna: novoCard.id_coluna,
          id_quadro: novoCard.id_quadro,
          id_criador: id_criadorValue,
          id_responsavel: id_responsavelValue,
          titulo_card: titulo_cardValue,
          descricao_card: descricao_cardValue,
          meta_conclusao: meta_conclusaoValue,
          image: nameArquivo,
          recorrente: recorrenteValue,
        };


        enviarEmail();

        const response1 = await axios.post(`${urlApi}/gerenciamento-card`, requestData1, { headers: { Authorization: `Bearer ${token}` } });

        setTimeout(function () {
          modalNovoCard()

        }, 1000);


        fetchCard();
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data.error;
          toast.error(errorMessage);
        }
      }

    }

  };

  const [emailRecebe, setEmailRecebe] = useState();
  const [nomeRecebe, setNomeRecebe] = useState();

  //extrai o email do usuário selecionado
  useEffect(() => {
    if (usuarioEmail && (usuarioEmail !== id)) {
      const usuarioEmail2 = usuarios.find(user => user.id_usuario == usuarioEmail);
      const emailRecebe2 = usuarioEmail2 ? usuarioEmail2.email : 'Usuário não encontrado';
      const nomeRecebe = usuarioEmail2 ? usuarioEmail2.nome : 'Usuário não encontrado';

      setEmailRecebe(emailRecebe2)
      setNomeRecebe(nomeRecebe)
    }
  }, [usuarioEmail]);


  //Renderiza o login do criador
  const usuarioCriador = usuarios.find(user => user.id_usuario === novoCard.id_criador);
  const loginCriador = usuarioCriador ? usuarioCriador.nome : '';

  //recebe o titulo do quadro e passa opor email
  const tituloQuadro = quadro.find(qua => qua.id_quadro === novoCard.id_quadro);
  const tituloQuadroFiltrado = tituloQuadro ? tituloQuadro.titulo_quadro : '';


  const enviarEmail = () => {

    axios.get(`${urlApi}/email-gerenciamento/novo-cartao`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        userTo: emailRecebe,
        criador: loginCriador,
        titulo: tituloQuadroFiltrado,
        nomeUsuario: nomeRecebe,
        titulo_cartao: tituloCartao
      }
    })
      .then(response => {
        console.log("E-mail enviado com sucesso:", response.data.message);
      })
      .catch(error => {
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Headers:", JSON.stringify(error.response.headers, null, 2));
          console.error("Data:", error.response.data);
        } else if (error.request) {
          console.error("Request não respondida:", error.request);
        } else {
          console.error("Erro na configuração da requisição:", error.message);
        }
        console.error("Configuração completa:", error.config);
      });
    setUsuarioEmail();
  };



  const [message, setMensagem] = useState('');
  const [nameArquivo, setNameArquivo] = useState('');
  const uploadImageOnChange = async (e) => {
    const selectedImage = e.target.files[0];

    const formData = new FormData();
    formData.append('image', selectedImage);
    setNameArquivo(selectedImage.name);

    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = await axios.post(`${urlApi}/upload-gerenciamento/upload-image`, formData, headers);

      // Verifica se o upload foi bem-sucedido
      if (!response.data.erro) {
        setMensagem(response.data.message);

        // Aqui você pode usar as informações do arquivo que o backend retornou
        const fileInfo = response.data.fileInfo;
        console.log("Detalhes do arquivo:", fileInfo);



      } else {
        setMensagem(response.data.message);
      }

    } catch (err) {
      if (err.response) {
        setMensagem(err.response.data.message);
      } else {
        setMensagem("Erro: verifique o tipo de arquivo que está sendo anexado");
      }
    }
  };



  const usuariosVisualizadores = visualizador
    .filter(v => v.id_quadro === novoCard.id_quadro) // Ou editarCartao.id_quadro, caso seja um quadro e não coluna
    .map(v => usuarios.find(user => user.id_usuario === v.id_usuario)) // Mapeia para encontrar os usuários correspondentes
    .filter(Boolean); // Remove possíveis undefined caso algum id_usuario não seja encontrado


  return (
    <div >
      <FormContainer ref={ref} onSubmit={handleSubmit}>

        <Div>

          <InputArea>
            <Label>Responsável:</Label>
            <select
              name="id_responsavel"
              isSearchable
              onChange={(e) => setUsuarioEmail(e.target.value)}
            >
              <option value="" >Escolha </option>
              {usuariosVisualizadores.map((user, index) => (
                <option key={index} value={user.id_usuario}>
                  {user.login} - {user.nome}
                </option>
              ))}
            </select>
          </InputArea>

          <InputArea>
            <Label>Data de Entrega:</Label>
            <Input
              name="meta_conclusao"
              type="date"
              min={new Date().toISOString().split("T")[0]}

            />
          </InputArea>

          <InputArea2>
            <Label>Recorrente:</Label>
            <Select2 name="recorrente" onChange={handleCheckboxChange}>
              <option value="" >Escolha....</option>
              <option value="Sim">Sim, vencimento mensal</option>
              <option value="Não">Não</option>
            </Select2>
          </InputArea2>


        </Div>
        <Div>
          <InputArea>
            <Label>Titulo:</Label>
            <InputTitle
              name="titulo_card"
              type="text"
              onChange={(e) => setTituloCartao(e.target.value)}
            />
          </InputArea>
        </Div>


        <Div>
          <TextAreaContainer>
            <Label>Descrição:</Label>
            <textarea id="descricao_card" name="msg" ></textarea>
          </TextAreaContainer>
        </Div>



        <Div>
          <form>
            <Label>Anexo:</Label>
            {message ? <p>{message}</p> : ""}
            <input type='file' name='image' onChange={uploadImageOnChange} />

          </form>
        </Div>



        <ButtonContainer>
          <Button title="Salvar" type="submit" >
            <ImCheckmark color={"#03A64A"} size={20} /> Criar Cartão
          </Button>
        </ButtonContainer>
      </FormContainer>
      <style jsx global>{`
      .swal2-container.custom-swal-container {
        z-index: 9999; 
      }
    `}</style>
    </div>

  );
};

export default Form;


